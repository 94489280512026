@font-face {
  font-family: poppins-bold;
  src: url("Poppins-Bold.7098ef2e.ttf");
  font-display: swap;
}

@font-face {
  font-family: poppins-black;
  src: url("Poppins-Black.09e5aff3.ttf");
  font-display: swap;
}

html {
  scroll-snap-type: y mandatory;
}

body {
  min-height: 85vh;
  padding-bottom: 470px;
  position: relative;
  overflow-x: hidden;
}

footer {
  min-height: 430px;
  width: 100%;
  color: #fff;
  text-align: center;
  scroll-snap-align: end;
  background-color: #383735;
  margin-top: 15px;
  padding-bottom: 5px;
  position: absolute;
  bottom: 0;
}

#account_footer {
  font-size: 20px;
  font-weight: bold;
}

#TalkdustInc_footer {
  padding: 10px 10px 20px;
  font-family: poppins-bold;
  font-size: 38px;
}

#TalkdustInc_footer b {
  font-family: poppins-black;
  font-size: 48px;
}

#about_footer, #legal_footer, #connect_footer {
  width: 60%;
  font-weight: 600;
  display: inline-block;
}

#about_footer p, #legal_footer p, #connect_footer p {
  font-family: roboto;
}

#account_footer a, #about_footer a, #legal_footer a {
  color: #fff;
  text-decoration: none;
  transition: color .25s 83.3333ms;
}

#account_footer a:hover, #about_footer a:hover, #legal_footer a:hover {
  color: #f47920;
}

#connect_footer a {
  text-decoration: none;
}

#connect_footer a:hover {
  color: #f47920;
}

#account_footer a {
  font-family: poppins-black;
}

#account_footer #not-authenticated {
  display: block;
}

body[data-authenticated] #account_footer #not-authenticated, body.authenticated #account_footer #not-authenticated, #account_footer #authenticated {
  display: none;
}

body[data-authenticated] #account_footer #authenticated, body.authenticated #account_footer #authenticated {
  display: block;
}

@media (min-width: 440px) {
  footer {
    min-height: 360px;
  }

  body {
    padding-bottom: 380px;
  }

  #about_footer, #legal_footer {
    width: 45%;
  }

  #legal_footer {
    margin-top: 15px;
    position: absolute;
    left: 50%;
  }

  #about_footer {
    position: relative;
    left: -25%;
  }
}

@media (min-width: 640px) {
  #core_footer {
    margin-top: 55px;
  }

  #about_footer {
    width: 25%;
    position: absolute;
    top: 25%;
    left: 5%;
  }

  #legal_footer {
    width: 25%;
    position: absolute;
    top: 25%;
    left: 70%;
  }
}

@media (min-width: 780px) {
  #about_footer {
    width: 25%;
    margin-right: 100px;
    position: absolute;
    top: 25%;
    left: auto;
    right: 50%;
  }

  #legal_footer {
    width: 25%;
    margin-left: 100px;
    position: absolute;
    top: 25%;
    left: 50%;
  }
}

/*# sourceMappingURL=footer.3d9ab3cf.css.map */
