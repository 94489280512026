@font-face {
    font-family: poppins-bold;
    src: url(/fonts/Poppins/Poppins-Bold.ttf);
    font-display: swap;
}
@font-face {
    font-family: poppins-black;
    src: url(/fonts/Poppins/Poppins-Black.ttf);
    font-display: swap;
}

html {
    scroll-snap-type: y mandatory;
}

body{
    min-height: 85vh;
    padding-bottom:470px;
    position:relative;
    /* scroll-snap-align: start; */
    overflow-x: hidden;
}

footer{
    min-height: 430px;
    background-color: #383735;
    width:100%;
    margin-top:15px;
    padding-bottom: 5px;
    color: white;
    position:absolute;
    bottom:0px;
    text-align: center;
    scroll-snap-align: end;
}

#account_footer{
  font-weight:bold;
  font-size: 20px;
}
#TalkdustInc_footer{
  font-family: poppins-bold;
  font-size: 38px;
  padding:10px;
  padding-bottom:20px;
}
#TalkdustInc_footer b{
  font-family: poppins-black;
  font-size: 48px;
}

#about_footer, #legal_footer, #connect_footer{
    width:60%;
    display: inline-block;
    font-weight: 600;
    /* margin-left:5%; */
}

#about_footer p, #legal_footer p, #connect_footer p{
    font-family: roboto;
}

#account_footer a, #about_footer a, #legal_footer a{
    text-decoration: none;
    color: white;
    transition: color 0.25s 0.0833333333s;
}
#account_footer a:hover, #about_footer a:hover, #legal_footer a:hover{
    color: #f47920;
}

#connect_footer a{
    text-decoration: none;
}

#connect_footer a:hover{
    color:#f47920;
}

#account_footer a{
  font-family: poppins-black;
}

#account_footer #not-authenticated{
  display:block;
}

body[data-authenticated] #account_footer #not-authenticated,
body.authenticated #account_footer #not-authenticated{
  display:none;
}

#account_footer #authenticated{
  display:none;
}

body[data-authenticated] #account_footer #authenticated,
body.authenticated #account_footer #authenticated{
  display:block;
}

@media (min-width: 440px){

    footer{
        min-height:360px;
    }

    body{
        padding-bottom: 380px;
    }

    #about_footer, #legal_footer{
        width:45%;
    }
    #legal_footer{
      margin-top:15px;
        position:absolute;
        left:50%;
    }
    #about_footer{
      position: relative;
      left: -25%;
    }
}

@media (min-width: 640px){
  #core_footer{
    margin-top:55px;
  }
    #about_footer{
        position:absolute;
        top:25%;
        left:5%;
        width:25%;
    }
    #legal_footer{
        top:25%;
        position:absolute;
        left:70%;
        width:25%;
    }
}
@media (min-width: 780px){

    #about_footer{
        position:absolute;
        top:25%;
        right:50%;
        left: auto;
        margin-right: 100px;
        width:25%;
    }
    #legal_footer{
        top:25%;
        position:absolute;
        left:50%;
        width:25%;
        margin-left: 100px
    }
}
